<template>
  <div class="page" id="create-auto-dialer">
    <Layout :step="1">
      <template v-slot:header>
        <h2 class="m-0">
          {{ $t('auto-dialer.create.step2.title') }} {{ campaign.name }}
        </h2>
      </template>
      <template v-slot:step-1>
        <div>
          <b-tabs pills nav-class="tabs-nav" fill :value="model.uploadMethod">
            <!-- <b-tab :title="$t('auto-dialer.create.step2.tabs.manually')">
              <form class="form-container">
                <div class="row">
                  <div
                    class="col-lg-4 col-md-6"
                    v-for="(customer, index) in model.customers"
                    :key="index"
                  >
                    <Customer
                      :customer="customer"
                      :index="index"
                      :errors="errors"
                      @removeCustomer="removeCustomer"
                    />
                  </div>
                </div>
                <b-button
                  type="button"
                  class="px-3 py-2"
                  @click="
                    model.customers.push({
                      name: '',
                      phone: '',
                      information: ''
                    })
                  "
                >
                  {{
                    $t('auto-dialer.create.step2.manually.form.add-customer')
                  }}
                </b-button>
              </form>
            </b-tab> -->

            <b-tab :title="$t('auto-dialer.create.step2.tabs.file')">
              <div class="d-flex justify-content-end mb-2">
                <b-button
                  type="button"
                  class="px-3 py-2"
                  @click="downloadTemplate"
                >
                  <i class="fa fa-download" aria-hidden="true"></i>
                  <span class="mx-1">
                    {{ $t('auto-dialer.create.step2.file.template') }}
                  </span>
                </b-button>
              </div>
              <DropzoneFile
                v-if="model.customersFileName && !customersFile"
                :file="{
                  name: model.customersFileName
                }"
                @removeFile="model.customersFileName = null"
              />

              <Dropzone
                v-else
                class="mb-2"
                :options="{
                  paramName: 'file',
                  acceptedFiles: '.csv',
                  maxFiles: 1
                }"
                v-model="customersFile"
              >
                <div class="dropzone-custom-content">
                  <h3 class="dropzone-custom-title">
                    {{ $t('auto-dialer.create.step2.file.form.file.title') }}
                  </h3>
                  <div class="subtitle">
                    {{ $t('auto-dialer.create.step2.file.form.file.subtitle') }}
                  </div>
                  <div class="description">
                    {{
                      $t('auto-dialer.create.step2.file.form.file.description')
                    }}
                  </div>
                </div>
              </Dropzone>
            </b-tab>
          </b-tabs>
          <div
            class="d-flex justify-content-end mt-4 stepper-pagination"
            v-if="
              campaign.status !== 'stopped' && campaign.status !== 'finished'
            "
          >
            <button
              class="px-3 py-2 btn btn-outline-primary"
              @click="back"
              type="button"
            >
              {{ $t('auto-dialer.create.step2.back') }}
            </button>

            <button
              class="px-3 py-2 btn btn-outline-success"
              @click="submit({ isDraft: true })"
              type="button"
              :disabled="loading"
            >
              {{
                loading
                  ? 'loading...'
                  : $t('auto-dialer.create.step2.save-as-draft')
              }}
            </button>

            <button
              class="px-3 py-2 btn btn-primary"
              @click="submit"
              type="button"
              :disabled="loading"
            >
              {{
                loading
                  ? $t('auto-dialer.create.step2.loading')
                  : $t('auto-dialer.create.step2.save-and-start')
              }}
            </button>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import Dropzone from '../../../elements/Dropzone.vue'
// import Customer from './components/Customer.vue'
import Layout from './layout.vue'
import { array, object, string } from 'yup'
import { yupToKV } from '../../../utils/yup'
import AutoDialerService from '../../../services/autoDialer.service'
import { onlyAcceptNumbers } from '../../../utils/utils'
import { isValidPhoneNumber } from '../../../utils/phone'

export default {
  components: {
    Layout,
    Dropzone
    // Customer
  },
  data() {
    return {
      uploadMethods: ['api', 'csv'],
      customersFile: null,
      loading: false,
      campaign: {},
      model: {
        uploadMethod: 1,
        customers: [{ name: '', phone: '', information: '' }]
      },
      errors: {}
    }
  },
  computed: {
    schema() {
      return object().shape({
        customers: array().of(
          object().shape({
            name: string().required(
              this.$t('auto-dialer.create.step2.manually.form.name.required')
            ),
            phone: string()
              .trim()
              .test(
                'isValidPhone',
                this.$t('auto-dialer.create.step2.manually.form.phone.invalid'),
                isValidPhoneNumber
              )
              .required(
                this.$t('auto-dialer.create.step2.manually.form.phone.required')
              ),
            information: string().nullable()
          })
        )
      })
    }
  },
  methods: {
    // utils
    onlyAcceptNumbers(event) {
      onlyAcceptNumbers(event)
    },
    async fetchCampaign() {
      try {
        const res = await AutoDialerService.getCampaign(this.$route.params.id)
        this.campaign = res.data.campaign
        // this.model.customers =
        //   res.data.campaign.customers.length > 0
        //     ? res.data.campaign.customers
        //     : [{ name: '', phone: '', information: '' }]
        this.model.uploadMethod = 1
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      }
    },
    async downloadTemplate() {
      try {
        const res = await AutoDialerService.downloadTemplate()
        // save to csv
        const blob = new Blob([res], {
          type: 'text/csv'
        })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'auto-dialer-template.csv'
        a.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.log(error)
      }
    },
    async submit({ isDraft = false }) {
      try {
        this.loading = true
        this.errors = {}

        // Submit form
        if (this.model.uploadMethod === 1) {
          if (!this.customersFile || !this.customersFile[0]) {
            this.toast('Please add customers file', {
              type: 'error'
            })
            return
          }

          await AutoDialerService.uploadCustomersFile(
            this.$route.params.id,
            this.customersFile[0],
            isDraft
          )
        } else {
          const data = {
            isDraft,
            customers: this.model.customers,
            type: this.uploadMethods[this.model.uploadMethod]
          }

          await this.schema.validate(data, { abortEarly: false }).catch(err => {
            this.errors = yupToKV(err)
          })

          if (!this.schema.isValidSync(this.model)) {
            return
          }

          await AutoDialerService.attachCustomersToCampaign(
            this.$route.params.id,
            data
          )
        }

        this.$router.push({
          name: 'auto-dialer',
          params: { id: this.$route.params.id }
        })
      } catch (error) {
        const errorMessage = error.response
          ? error.response.data.message
          : error.message
        this.toast(errorMessage, {
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    addCustomer() {
      this.model.customers.push({ name: '', phone: '', information: '' })
    },
    async removeCustomer(index) {
      this.model.customers.splice(index, 1)
      // Reset errors
      this.errors[`customers[${index}].name`] = null
      this.errors[`customers[${index}].phone`] = null
    },
    back() {
      this.$router.push({
        name: 'auto-dialer-update',
        params: { id: this.$route.params.id }
      })
    }
  },
  mounted() {
    this.fetchCampaign()
  }
}
</script>

<style lang="scss">
.tabs-nav {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  .nav-link {
    border: 1px solid #008ecf;
  }
}
</style>
