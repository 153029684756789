var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"create-auto-dialer"}},[_c('Layout',{attrs:{"step":1},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.create.step2.title'))+" "+_vm._s(_vm.campaign.name)+" ")])]},proxy:true},{key:"step-1",fn:function(){return [_c('div',[_c('b-tabs',{attrs:{"pills":"","nav-class":"tabs-nav","fill":"","value":_vm.model.uploadMethod}},[_c('b-tab',{attrs:{"title":_vm.$t('auto-dialer.create.step2.tabs.file')}},[_c('div',{staticClass:"d-flex justify-content-end mb-2"},[_c('b-button',{staticClass:"px-3 py-2",attrs:{"type":"button"},on:{"click":_vm.downloadTemplate}},[_c('i',{staticClass:"fa fa-download",attrs:{"aria-hidden":"true"}}),_c('span',{staticClass:"mx-1"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.create.step2.file.template'))+" ")])])],1),(_vm.model.customersFileName && !_vm.customersFile)?_c('DropzoneFile',{attrs:{"file":{
                name: _vm.model.customersFileName
              }},on:{"removeFile":function($event){_vm.model.customersFileName = null}}}):_c('Dropzone',{staticClass:"mb-2",attrs:{"options":{
                paramName: 'file',
                acceptedFiles: '.csv',
                maxFiles: 1
              }},model:{value:(_vm.customersFile),callback:function ($$v) {_vm.customersFile=$$v},expression:"customersFile"}},[_c('div',{staticClass:"dropzone-custom-content"},[_c('h3',{staticClass:"dropzone-custom-title"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.create.step2.file.form.file.title'))+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.create.step2.file.form.file.subtitle'))+" ")]),_c('div',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.create.step2.file.form.file.description'))+" ")])])])],1)],1),(
            _vm.campaign.status !== 'stopped' && _vm.campaign.status !== 'finished'
          )?_c('div',{staticClass:"d-flex justify-content-end mt-4 stepper-pagination"},[_c('button',{staticClass:"px-3 py-2 btn btn-outline-primary",attrs:{"type":"button"},on:{"click":_vm.back}},[_vm._v(" "+_vm._s(_vm.$t('auto-dialer.create.step2.back'))+" ")]),_c('button',{staticClass:"px-3 py-2 btn btn-outline-success",attrs:{"type":"button","disabled":_vm.loading},on:{"click":function($event){return _vm.submit({ isDraft: true })}}},[_vm._v(" "+_vm._s(_vm.loading ? 'loading...' : _vm.$t('auto-dialer.create.step2.save-as-draft'))+" ")]),_c('button',{staticClass:"px-3 py-2 btn btn-primary",attrs:{"type":"button","disabled":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.loading ? _vm.$t('auto-dialer.create.step2.loading') : _vm.$t('auto-dialer.create.step2.save-and-start'))+" ")])]):_vm._e()],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }